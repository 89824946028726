<template>
  <div class="search-widget">
    <v-search
      v-if="key_up === 1"
      :show-tabs="showTabs"
      brand-theme="light"
      :custom-lang="lang"
      :custom-class="classes_string"
      :brand-slug="changed_slug ? changed_slug : brand_slug"
      :venue-select="venue_select"
      :lane-select="lane_select"
      :adults-select="adults_select"
      :children-select="children_select"
      :infants-select="infants_select"
      :senior-select="senior_select"
      :dsb-select="dsb_select"
      :active-tab="activeTab"
      :hide-tabs="hideTabs"
      :date-start="dateStart"
      :date-end="dateEnd"
      :button-text="changed_buttonText ? changed_buttonText : buttonText"
      :use-12h="set_12_hr"
      :emit-search="emit_search"
      :loading-data="loading_data"
      :fetch-images="fetch_images"
      :hide-venue-select="hide_venue_select"
      :results-domain="results_domain"
      :pof="pof"
    ></v-search>
  </div>
</template>
<script>
// v-search is a custom element
// the js needed is in index.html
// isCustomElement is set in vue.config.js

export default {
  name: "SearchWidget",
  components: {},
  props: {
    brand_slug: String,
    activeTab: String,
    showTabs: String,
    hideTabs: String,
    dateStart: String,
    dateEnd: String,
    buttonText: String,
    lang: String,
    venue_select: String,
    lane_select: String,
    adults_select: String,
    children_select: String,
    infants_select: String,
    senior_select: String,
    dsb_select: String,
    custom_class: String,
    emit_search: String,
    loading_data: String,
    fetch_images: String,
    hide_venue_select: String,
    pof: String,
  },
  data() {
    return {
      key_up: 0,
      classes_string: "",
      changed_slug: "",
      results_domain: "",
    };
  },
  computed: {},
  methods: {},
  mounted() {
    this.classes_string = `tabs-left large-strings ${
      this.custom_class ? this.custom_class : ""
    }`;
    if (this.brand_slug === "NWI" && !this.emit_search) {
      this.classes_string = ``;
    }
    // if (this.brand_slug === "FM1") {
    //   this.classes_string = `tabs-left`;
    // }
    if (this.brand_slug === "BHX") {
      this.classes_string = this.classes_string + ` alt-inputs`;
      //this.changed_buttonText = "Get a Quote ➜";
    }
    if (this.brand_slug === "BH1") {
      this.changed_slug = "BHX";
      this.results_domain = "BH1";
    }
    if (this.brand_slug === "SV1") {
      this.changed_buttonText = "View Rates";
    }

    this.key_up = 1;
  },
  updated() {},
};
</script>

<style scoped lang="scss">
.search-widget {
  position: relative;
  // max-width: 1260px;
  margin: 0 auto;
  z-index: 9999;

  @media screen and (max-width: 1260px) {
    padding: 0 1rem;
  }

  @media screen and (max-width: 768px) {
    padding: 0rem 0rem 0 0rem;
  }
}
</style>
